<template>
    <div class="content">
        <div class="header">
            <div class="data-title">
                <div class="cot"></div>
                <h3>数据总览</h3>
                <span>（{{allnum}}条）</span>
                <div class="nav">
                    <div :class="styleIndex == index + 1 ? 'nav-item-index' : 'nav-item'"
                        v-for="(item,index) in tabList" :key="item.id"
                        @click="styleNav(`${index + 1}`); navTo(`/upload?styleID=${item.id}`)">
                        <p>{{ item.name }}</p>
                        <div class="nav-item-btn" v-if="styleIndex == index + 1"></div>
                    </div>
                </div>
            </div>
            <User></User>
        </div>
        <!-- 账号信息 -->
        <div class="userName">
            <h3>账号信息</h3>
            <div class="userName-data">
                <p v-if="userData.bankCode">绑定银行卡卡号：{{ userData.bankName }} {{ userData.bankCode }}</p>
                <p v-if="!userData.bankCode">绑定银行卡卡号：暂未绑定银行卡</p>
                <!-- <span>点击修改</span> -->
                <button @click="dialogFormVisible = true">点击修改</button>
                <!-- 修改弹出层 -->
                <el-dialog title="修改银行卡号" :visible.sync="dialogFormVisible">
                    <el-form :model="form">
                        <div style="width:500px;margin:0 auto;">
                            <el-form-item label="银行卡开户行" label-width="100px">
                                <el-input type="text" placeholder="请输入银行卡开户行" clearable v-model="bankText"></el-input>
                            </el-form-item>
                            <el-form-item label="银行卡号：" label-width="100px">
                                <el-input type="number" placeholder="请输入银行卡号吗" clearable v-model="bankCard"></el-input>
                            </el-form-item>
                        </div>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogFormVisible = false">取 消</el-button>
                        <el-button type="primary" @click="postGetBankcardinfo()">确 定</el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
        <!-- 收益记录 -->
        <div class="from">
            <div class="invoice-upload">
                <div class="upload-header">
                    <div class="upload-header-title">
                        <h4>发票详情 <span v-if="navBillIndex == 2">(账单合计收入{{ tablemomey || 0 }})</span><span
                                v-if="navBillIndex == 1" style="margin-right: 8em;"></span></h4>
                        <span :class="navBillIndex == 1 ? 'form-header-nav-item' : 'form-header-nav-itemspan'"
                            @click="navBill(1)">已出账单</span>
                        <span :class="navBillIndex == 2 ? 'form-header-nav-item' : 'form-header-nav-itemspan'"
                            @click="navBill(2)">结算账单</span>
                    </div>
                    <!-- <el-date-picker v-model="value" type="month" format="yyyy 年 M 月" :clearable="false"
                        prefix-icon="none" :editable="false" align="center" @change="dateInputChange">
                    </el-date-picker> -->
                </div>
                <div>
                    <div class="form-nav" v-if="navBillIndex == 1">
                        <div class="form-nav-item" @click="formNavHeader(1)">
                            <p :class="formNavHeaderID == 1 ? 'form-nav-itemYES' : ''">确定结算单</p>
                            <div v-if="formNavHeaderID == 1" class="form-nav-item-btn"></div>
                        </div>
                        <div class="form-nav-item" @click="formNavHeader(2)">
                            <p :class="formNavHeaderID == 2 ? 'form-nav-itemYES' : ''">下载签字并打印</p>
                            <div v-if="formNavHeaderID == 2" class="form-nav-item-btn"></div>
                        </div>
                        <div class="form-nav-item" @click="formNavHeader(3)">
                            <p :class="formNavHeaderID == 3 ? 'form-nav-itemYES' : ''">账单及发票上传单号填写</p>
                            <div v-if="formNavHeaderID == 3" class="form-nav-item-btn"></div>
                        </div>
                        <div class="form-nav-item" @click="formNavHeader(4)">
                            <p :class="formNavHeaderID == 4 ? 'form-nav-itemYES' : ''">等待财务打款</p>
                            <div v-if="formNavHeaderID == 4" class="form-nav-item-btn"></div>
                        </div>
                    </div>
                    <!-- 表格 -->
                    <div class="upload-header-tabble">

                        <el-table :data="tableData" style="width: 100%">
                            <el-table-column prop="jiesuanRange" label="周期"> </el-table-column>
                            <el-table-column prop="hetong" label="结算合同号"></el-table-column>
                            <el-table-column prop="reportCode" label="结算单号"></el-table-column>
                            <el-table-column prop="payMoney" label="结算单含税收入(元)"></el-table-column>
                            <el-table-column prop="realMoney" label="应付金额(元)"></el-table-column>
                            <el-table-column prop="logistCode" label="快递单号" v-if="formNavHeaderID == 4">
                            </el-table-column>
                            <el-table-column prop="statuc" label="状态" width="200"
                                v-if="formNavHeaderID != 3 && navBillIndex == 1">
                                <template slot-scope="scope">
                                    <div class="statuc-item" v-if="formNavHeaderID == 1">
                                        <span>待确定账单</span>
                                        <button class="statuc-link" @click="determineBill(scope.row.id)">确定账单</button>
                                    </div>
                                    <div class="statuc-item" v-if="formNavHeaderID == 2">
                                        <span>已确定账单</span>
                                        <!-- 下载文件 -->
                                        <button class="statuc-link"
                                            @click="formUploader(scope.row.id, scope.row.jiesuanFile)">下载账单</button>
                                    </div>
                                    <div class="statuc-item" v-if="formNavHeaderID == 4">
                                        <span>等待打款</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="statuc" label="状态" width="380" v-if="formNavHeaderID == 3">
                                <template slot-scope="scope">

                                    <div class="statuc-item" v-if="formNavHeaderID == 3 && navBillIndex == 1">
                                        <span>已下载账单</span>
                                        <!-- 下载文件 -->
                                        <!-- action	必选参数，上传的地址  七牛云：http://up-z1.qiniu.com/-->
                                        <!-- data	上传时附带的额外参数 -->
                                        <!-- on-success	文件上传成功时的钩子 -->
                                        <!--  -->
                                        <!-- before-upload	上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。 -->
                                        <el-upload action="#" :http-request="billhttpRequest" :multiple="false"
                                            :show-file-list="false" :before-upload="beforeAvatarUpload">
                                            <button class="statuc-link">上传账单</button>
                                        </el-upload>
                                        <el-upload action="#" :http-request="ivhttpRequest" :multiple="false"
                                            :show-file-list="false" :before-upload="beforeAvatarUpload">
                                            <button class="statuc-link">上传发票</button>
                                        </el-upload>

                                        <button class="statuc-link" @click="uploadNumberBool = true">上传单号</button>
                                        <!-- 上传单号弹出层 -->
                                        <el-dialog title="上传单号" :visible.sync="uploadNumberBool">
                                            <el-form :model="form">
                                                <div style="width:500px;margin:0 auto;">
                                                    <el-form-item label="单号" label-width="100px">
                                                        <el-input type="text" placeholder="请输入单号" clearable
                                                            v-model="uploadNumber"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-form>
                                            <div slot="footer" class="dialog-footer">
                                                <el-button @click="uploadNumberBool = false">取 消</el-button>
                                                <el-button type="primary" @click="postUploadData()">确 定</el-button>
                                            </div>
                                        </el-dialog>

                                        <button class="statuc-link statuc-btn"
                                            @click="PostStatuUpload(scope.row.id)">确定</button>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- momStatus -->
                            <el-table-column prop="statuc" label="状态" width="380" v-if="navBillIndex == 2">
                                <template>
                                    <div class="statuc-item">
                                        <span>已结算</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 分页 -->
                    <div class="pagination">
                        <el-pagination background layout="prev, pager, next" :total="tableDataCount"
                            @current-change="currentChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import User from "./../../components/user.vue"
import getBankcardinfo from 'bankcardinfo'
import { Message } from "element-ui";


export default {
    components: {
        User
    },
    data() {
        return {
            styleID: 0,
            tabList: [],//导航栏
            styleIndex: null,//导航栏下标
            userData: [],//用户信息
            navBillIndex: 1,//账单下标
            uploadNavID: 0,
            formNavHeaderID: 1,
            value: new Date(),
            dateValue: '',//时间戳
            dialogFormVisible: false,//修改银行卡弹出程
            form: [],//修改银行卡弹出层
            bankText: '',//银行卡开户行
            bankCard: '',//银行卡号码
            labelList: {
                // 确定结算单
                determine: [{ prop: 'data', label: '周期', width: '200' }]
            },
            // 表格数据
            tableData: [],
            tableDataCount: 0,
            tablemomey: null,//列表金额
            code: 1,
            postData: {
                // 上传时要带上附带的token
                token: ''
            },
            billUrl: "",//账单文件路径
            ivUrl: "",//发票文件路径
            uploadNumberBool: false,//上传单号
            uploadNumber: '',//单号
            uploadNumberYes: '',//实际上传的单号
            status: 0,
            allnum: 0
        }
    },
    created() {
        this.postData.token = localStorage.getItem('token')
        this.styleID = this.$route.query.styleID;
        this.tabFN();//tab
        this.PostUser();// 获取用户信息
        if (this.navBillIndex == 1) {
        } else if (this.navBillIndex == 2) {
            this.status = 5;
        }
        this.PostbiillData(this.value);//获取列表
    },
    methods: {
        // tab
        tabFN() {
            this.tabList = JSON.parse(localStorage.getItem('upload'))
            this.tabList.forEach((item, index) => {
                // console.log(this.styleID == item.id);
                // return
                if (this.styleID == item.id) {
                    this.styleIndex = index + 1
                }
            })
        },
        // 获取用户信息
        PostUser() {
            let userID = localStorage.getItem('userID');
            this.$api.user({
                userId: userID
            }).then(res => {
                this.userData = res.data

            })
        },
        // 查看账单列表 biillData
        PostbiillData(date) {
            console.log('*************************');
            let time = this.$formDate(Date.parse(date), 'M', '-');
            this.$api.biillData({
                timed: time,
                status: this.status,//状态
                tai: this.styleID,
                pagecode: 1,
                pagesize: 10,
            }).then(res => {
                res.data.forEach((item, index) => {
                    item.createtime = this.$formDate(item.createtime, 'M', '-', 1000);
                })
                this.tablemomey = res.tablemomey;
                this.allnum = res.allnum;
                this.tableDataCount = res.count
                this.tableData = res.data
            })
        },

        // 时间切换
        dateInputChange(e) {
            this.value = e
            this.PostbiillData(e)
        },
        // 文件下载
        upload(url) {
            window.open(url)
        },
        // 顶部nav
        styleNav(number) {
            console.log(number, 'id');
            this.styleIndex = number
        },
        // 订单列表切换
        formNavHeader(number) {
            this.status = number - 1;
            this.formNavHeaderID = number
            this.PostbiillData(this.value);
        },
        // 订单导航
        navBill(number) {
            this.tableData = [];
            this.tableDataCount = 0;
            this.navBillIndex = number;
            if (number == 1) {
                this.formNavHeaderID = 1
            } else if (number == 2) {
                this.formNavHeaderID = 5
            }
            this.PostbiillData(this.value);//获取订单列表
        },
        // 确定订单
        determineBill(id) {
            this.$api.dateBill({
                id: id,
                status: 1,
            }).then(res => {
                Message.success('已确定该订单')
                this.PostbiillData(this.value)
            })
        },
        // 分页
        currentChange() {
            this.code = e
            this.PostbiillData(this.value);
        },
        // 下载文件
        formUploader(id, url) {
            if (url) {
                this.PostuploadStatuc(id)
                console.log(url, '下载文件');
                window.open(url, '账单')
            } else {
                Message.error('下载失败')
            }
        },
        // 下载成功的请求（改变状态）
        PostuploadStatuc(id) {
            this.$api.uploadStatuc({
                id: id,
                status: 2
            }).then(res => {

            })
        },
        // 银行卡
        postGetBankcardinfo() {

            let userID = localStorage.getItem('userID');
            // 做银行开户行的汉字校验
            // 做银行账号的校验
            let Text = /[\u4e00-\u9fa5]./;
            let Card = /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/;

            let TextBool = Text.test(this.bankText);
            let CardBool = Card.test(this.bankCard);

            if (this.bankCard && this.bankText) {
                if (TextBool && CardBool) {
                    this.$api.modifyUser({
                        id: userID,
                        nickname: this.userData.nickname,
                        avatar: this.userData.avatar,
                        bankName: this.bankText,
                        bankCode: this.bankCard
                    }).then(res => {
                        this.PostUser();//获取用户信息
                        this.dialogFormVisible = false
                        Message.success(res.msg)

                    })
                } else {
                    Message.error('请输入正确的开户行/卡号')
                }
                // 修改信息

                // let text = this.bankCard
                // getBankcardinfo.getBankBin(text, (err, data) => {
                //     if (!err) {
                //         // 银行卡开户行
                //
                //         alert(data.bankName)

                //     } else {
                //         // 银行账号错误
                //         alert('银行卡账号错误,请重新输入')
                //     }
                // })
            } else {
                Message.error('请完善信息')
            }
        },


        // 自定义请求
        billhttpRequest(param) {
            let fd = new FormData();
            fd.append('uploadFile', param.file);
            this.$api.upload(fd).then(res => {
                this.billUrl = res.url;
                Message.success('文件上传成功')
            })
        },
        // 自定义请求
        ivhttpRequest(param) {
            let fd = new FormData();
            fd.append('uploadFile', param.file);
            this.$api.upload(fd).then(res => {
                this.ivUrl = res.url;
                Message.success('文件上传成功')
            })
        },
        // 文件上传之前的沟子
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg' || 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            // return isJPG && isLt2M;
            return isLt2M
        },
        // 单号弹出层确定
        postUploadData() {
            this.uploadNumberYes = this.uploadNumber
            this.uploadNumberBool = false
        },
        // 确定
        async PostStatuUpload(id) {
            // console.log(this.billUrl, this.ivUrl, this.uploadNumberYes);
            // if(this.billUrl &&  this.ivUrl && this.uploadNumberYes){

            // }else{
            //     Message.error('未按')
            // }
            if (!this.billUrl) {
                Message.error('请上传账单')
                return;
            }
            if (!this.ivUrl) {

                Message.error('请上传发票')
                return;
            }
            if (!this.uploadNumberYes) {
                Message.error('请填写快递单号')
                return;
            }
                // 请求接口
            this.$api.uploadData({
                    id: id,
                    account: this.billUrl,
                    bill: this.ivUrl,
                    logist_code: this.uploadNumberYes
                }).then(res => {
                    if(res.error_code === 0) {
                        Message.success(res.message)
                    } else {
                        Message.error(res.message)
                    }
                })
        }










    },

    beforeRouteUpdate(to, from, next) {
        // console.log(this.styleID);
        // this.$store.commit('STYLENAV',this.styleID)

        next()
        this.$router.go(0)
    }
}
</script>
<style scoped>
h3 {
    font-size: 1em;
    color: #000000;
}

.content {
    min-height: 100vh;
    background-color: #eee;

}

.header {
    width: 90%;
    margin: 0 auto;
    padding-top: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav {
    display: flex;
    margin-left: 2em;
}

.nav-item {
    padding: 0 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.nav-item-index {
    padding: 0 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: #2e4fff;
}

.nav-item p {
    color: #666666;
}

.nav-item-btn {
    content: '';
    width: 2em;
    height: 3px;
    background-color: #2e4fff;
    bottom: -1em;
    position: absolute;
}

.userName {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    padding: 2em;
    box-sizing: border-box;
    margin-top: 2em;
}

.userName-data {
    margin-top: 1em;
    display: flex;
}

.userName-data p {
    color: #666;
}

.userName-data button {
    border: none;
    background-color: #fff;
    margin-left: 3em;
    color: #5a75ff;
}


.invoice-upload {
    margin-top: 1em;
}

.from {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    margin-top: 2em;
    /* padding: 2em 0; */
    padding-bottom: 2em;
}

.form-nav {
    /* width: 90%; */
    margin: 0 auto;
}

.upload-header {
    padding: 1em 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.upload-header-title {
    display: flex;
}

.upload-header-title h4 span {
    font-size: 0.8em;
    margin-left: 10px;
}

.upload-header-tabble {
    margin-top: 1em;
}

.form-header-nav-item {
    /* background-color: red; */
    color: #2e4fff;
}

.form-header-nav-itemspan {
    color: #999999;
}

.upload-header-title span {
    margin-left: 0.5em;
    padding: 0 1em;
    /* color: #4c4c4c; */
}

.pagination {
    margin-top: 1em;
    text-align: center;
}

/* form-nav">
                        <div class="form-nav-item">
                            <p>确定结算单</p>
                            <div class="form-nav-item-btn">< */
.form-nav {

    display: flex;
    /* flex-direction: column;
    align-items: center; */
}

.form-nav-item {
    padding: 0 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-nav-itemYES {
    color: #2e4fff;
}

.form-nav-item-btn {
    content: '';
    width: 60%;
    height: 2px;
    margin-top: 6px;
    background-color: #2e4fff;
}

.statuc-item {
    white-space: nowrap;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-around;
}

.statuc-item span {
    margin-right: 20px;
}

.statuc-item button {
    margin-right: 20px;
}

.statuc-item span:last-child {
    margin-right: 0;
}

.statuc-item button:last-child {
    margin-right: 0;
}

.statuc-link {
    border: none;
    /* background-color: #fff; */
    background: rgba(0, 0, 0, 0);
    color: #2e4fff;
}

.statuc-btn {
    border: 1px solid #2e4fff;
    padding: 2px 5px;
    background-color: rgba(94, 96, 205, 0.2);
}
</style>
