<template>
    <div class="userBox">
        <div class="cont-icon">
            <div class="icon-item" @click="navTo('/my')">
                <img src="~@/assets/user/setUp.png" alt="">
            </div>

            <div class="icon-item" @click="navTo('/contract')">
                <img src="~@/assets/user/list.png" alt="">
            </div>
            <div class="icon-item" @click="navTo('/news')">
                <img src="~@/assets/user/notice.png" alt="">
                <div v-if="boolean" class="circular"></div>
            </div>
        </div>
        <div class="cont-user" >
            <div class="userImg">
                <img :src="avatar" alt="">
            </div>
            <h5>{{nickname}}</h5>
            <i class="el-icon-arrow-down" @click="navTo('/my')"></i>
            <!-- <div class="sign" v-if="false">
                <div class="signOutBtn">
                    <div class="signOutBtn-triple"></div>
                    <button>退出登陆</button>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{
            nickname:'',
            avatar:'',
            boolean:false,//是否有未读消息
        }
    },
    created(){
        this.PostUser();//获取用户信息
        this.PostfindMyxiaoxi();
    },
    methods: {
        // 获取用户信息
        PostUser(){
            let userID = localStorage.getItem('userID');
            this.$api.user({
                userId:userID
            }).then(res=>{
                this.nickname = res.data.nickname;
                this.avatar = res.data.avatar;
                localStorage.setItem('userName',res.data.nickname);//昵称
                localStorage.setItem('avatar',res.data.avatar);//头像
                localStorage.setItem('bankName',res.data.bankName);//开户行
                localStorage.setItem('bankCode',res.data.bankCode);//银行卡
                localStorage.setItem('upload',JSON.stringify(res.plate))

            })
        },
        // 查看未读信息
        PostfindMyxiaoxi(){
            let userID = localStorage.getItem('userID')
            this.$api.findMyxiaoxi({
                userId:userID
            }).then(res=>{
                this.boolean = res.data
            })
        },
    },
}
</script>
<style scoped>
.userBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* padding-top: 1em; */
}

.cont-icon {
    /* display: inline-block; */
}

.icon-item {
    display: inline-block;
    margin-right: 2em;
    position: relative;
}

/* .icon-item:last-child{
    margin-right: 0;
} */
.icon-item img {
    widows: 1em;
    height: 1em;
}
.circular{
    right: 0;
    top: 0;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: red;
}
.cont-user {
    /* display: inline-block; */
    display: flex;
    align-items: center;
    position: relative;
}

.cont-user .userImg {
    width: 2em;
    height: 2em;
    border-radius: 100%;
    /* background-color: red; */
    margin-right: 1em;
}

.cont-user .userImg img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.cont-user h5 {
    font-size: 1em;
    margin-right: 1em;
}

.signOutBtn {
    width: 10em;
    background-color: red;
    right: 0;
    bottom: -1.6em;
    /* top: 0; */
    position: absolute;
}


/* <div class="signOutBtn">
                <div class="signOutBtn-triple"></div>
                <button>退出登陆</button>
            </div> */
.sign{
    right: 0;
    bottom: -30px;
    position: absolute;
}
.signOutBtn {
    display: inline-block;
    position: relative;
    margin-top: 15px;
    /* height: 30px; */
    /* line-height: 30px; */
    background-color: rgba(183, 179, 179, 0.6);
    color: #fff;
    text-align: left;
    padding: 8px;
    border-radius: 3px;
    font-size: 1em;
    letter-spacing: 0.1em;
}

.signOutBtn-triple {
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: rgba(183, 179, 179, 0.6);
    position: absolute;
    right: 12px;
    top: -16px;
}
.signOutBtn button{
    width: 100%;

    line-height: 2em;
    border: none;
}
</style>
